.react-tabs__tab {
    box-sizing: border-box;
    border: 0px;
    cursor: pointer;
    display: inline-block;
    color: var(--custom-primary-color);
    padding: 18px 20px;
    font-size: 15px;
    font-weight: 700;
    margin-right: 10px;
    text-transform: uppercase;
}

.react-tabs {
    padding-left: 24px;
}

.react-tabs__tab-list {
    border-bottom: 0px;
}

.react-tabs__tab--selected {
    border-bottom: 2px solid var(--custom-primary-color) !important;
    pointer-events: none;
}

.react-tabs__tab-panel {
    // margin-top: 30px;
}

.dashboard-button {
    width: 420px;
    font-size: 20px;
    color: var(--custom-primary-color);
}

.MuiAccordionSummary-content {
    margin: 10px 0 !important;
}

.page-title {
    font-size: 28px;
    margin: 0;
    line-height: 75px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: inherit;
}
.sv_window {
  position: fixed;
  bottom: 3px;
  right: 10px;
  background-color: cadetblue;
  padding: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
}

.sv_window_title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.sv_window_content {
  padding: 8px;
  margin: 0;
}

.sv_window_title a,
.sv_window_title a:link,
.sv_window_title a:visited {
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  color: black;
}

.sv_main.sv_frame .sv_container {
  max-width: 80%;
  margin: auto;
  padding: 0 1em;
}

.sv_main.sv_frame .sv_container .sv_header {
  padding-top: 5em;
  padding-bottom: 1em;
}

/* .sv_main {
  background-color: #f4f4f4;
} */

.sv_main hr {
  border-bottom: 1px solid #e7e7e7;
}

.sv_main input[type="button"],
.sv_main button {
  color: white;
  /* background-color: var(--custom-btn-primary-color);  */
}

.sv_main input[type="button"]:hover,
.sv_main button:hover {
  /* background-color: var(--custom-primary-color);  */
}

.sv_main .sv_q_other input,
.sv_main .sv_q_text_root,
.sv_main .sv_q_dropdown_control,
.sv_main input[type="text"],
.sv_main select,
.sv_main textarea {
  border: 1px solid #e7e7e7;
  color: #6d7072;
  background-color: white;
}

.sv_main .sv_q_other input:focus,
.sv_main .sv_q_text_root:focus,
.sv_main .sv_q_dropdown_control:focus,
.sv_main input[type="text"]:focus,
.sv_main select:focus,
.sv_main textarea:focus {
  border: 1px solid var(--custom-color);
}

.sv_main .sv_select_wrapper {
  background-color: white;
}

.sv_main .sv_select_wrapper:before {
  background-color: var(--custom-color);
}

.sv_main .sv_header {
  color: #000000;
}
/* 
.sv_main .sv_custom_header {
  background-color: #e7e7e7;
} */

.sv_main .sv_container {
  color: #6d7072;
}

.sv_main .sv_body {
  background-color: white;
  /* border-top: 2px solid var(--custom-primary-color); */
}

.sv_main .sv_progress {
  background-color: #e7e7e7;
}

.sv_main .sv_progress_bar {
  background-color: var(--custom-primary-color);
}

.sv_main .sv_p_root>.sv_row {
  /* border-bottom: 1px solid #e7e7e7; */
}

.sv_main .sv_p_root>.sv_row:nth-child(odd) {
  /* background-color: white;  */
}

.sv_main .sv_p_root>.sv_row:nth-child(even) {
  background-color: #f4f4f4;
}

.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
  border: 1px solid #ed5565;
  background-color: #fcdfe2;
  color: #ed5565;
}

.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"])+input,
.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"])+textarea,
.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"])+.sv_select_wrapper select {
  border: 1px solid #ed5565;
}

.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"])+input:focus,
.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"])+textarea:focus,
.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"])+.sv_select_wrapper select:focus {
  outline: #ed5565 auto 5px;
}

.sv_main table.sv_q_matrix tr,
.sv_main table.sv_q_matrix_dropdown tr,
.sv_main table.sv_q_matrix_dynamic tr {
  border-bottom: 1px solid #e7e7e7;
}

.sv_main .sv_q_m_cell_selected {
  color: white;
  background-color: var(--custom-primary-color);
}

.sv_main .sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: var(--custom-color);
  border-color: var(--custom-color);
  color: white;
}

.sv_main .sv_q_rating_item .sv_q_rating_item_text {
  border: 1px solid #e7e7e7;
}

.sv_main .sv_q_rating_item .sv_q_rating_item_text:hover {
  border: 1px solid var(--custom-color);
}

.sv_main {
  position: relative;
  width: 100%;
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans",
    "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
}

.sv_main hr {
  border: none;
}

.sv_main input[type="button"],
.sv_main button {
  font-size: 0.85em;
  font-weight: bold;
  line-height: 2em;
  border: none;
  min-width: 100px;
  cursor: pointer;
  padding: 0 2em;
  border-radius: 2px;
}

.sv_main .sv_custom_header {
  position: absolute;
  width: 100%;
  height: 74%;
  max-height: 275px;
}

.sv_main .sv_container {
  position: relative;
}

.sv_main .sv_container .sv_header {
  padding: 1em 0em;
}

.sv_main .sv_container .sv_header h3 {
  font-size: 2.3em;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

.sv_main .sv_container .sv_body {
  padding: 1em 1em 5em 1em;
}

.sv_main .sv_container .sv_body.sv_completed_page {
  text-align: center;
  padding: 5em 1em;
}

.sv_main .sv_container .sv_body.sv_completed_page>h3 {
  margin: 0;
  font-size: 30px;
  font-weight: 300;
}

.sv_main .sv_container .sv_body .sv_progress {
  height: 0.3em;
}

.sv_main .sv_container .sv_body .sv_progress .sv_progress_bar {
  height: 100%;
  position: relative;
  margin-top: 2em;
}

.sv_main .sv_container .sv_body .sv_progress .sv_progress_bar>span {
  position: absolute;
  top: -1.5em;
  display: inline-block;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_page_title {
  font-size: 1.72em;
  margin-top: 0.1em;
  font-weight: normal;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_p_title {
  font-weight: bold;
  font-size: 1.15em;
  margin: 1.5em 0 0 0;
}

.sv_main .sv_container .sv_body .sv_p_root>.sv_row {
  padding: 0;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
  font-weight: bold;
  font-size: 1em;
  margin: 1em 0;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_description {
  margin-top: -0.6em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q {
  padding: 0.5em 1em 1.5em 0em;
  box-sizing: border-box;
  overflow: auto;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_panel_dynamic .sv_q_title {
  font-weight: normal;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
  margin: 1em 0;
  padding: 1em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_other input,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_text_root,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control,
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="text"],
.sv_main .sv_container .sv_body .sv_p_root .sv_q select,
.sv_main .sv_container .sv_body .sv_p_root .sv_q textarea {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 2em;
  padding-left: 1em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_other input:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_text_root:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="text"]:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q select:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q textarea:focus {
  outline: none;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q div.sv_q_text_root,
.sv_main .sv_container .sv_body .sv_p_root .sv_q div.sv_q_dropdown_control {
  min-height: 2.43em;
  min-width: 7em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper select {
  display: block;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 2.5em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper select::-ms-expand {
  display: none;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper:before {
  padding: 1em;
  position: absolute;
  right: 1px;
  right: 0;
  top: 1px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzNCAzNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQgMzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMTIsMTYgMTQsMTQgMTcsMTcgMjAsMTQgMjIsMTYgMTcsMjEgIi8+DQo8L3N2Zz4NCg==);
  content: "";
  background-repeat: no-repeat;
  background-position: center;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="color"] {
  min-height: 2em;
  padding: 0;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="radio"],
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="checkbox"] {
  margin: 0;
  margin-right: 0.55em;
  width: 1.2em;
  height: 1.2em;
  vertical-align: middle;
  font-size: 1em;
  margin-top: -0.1em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="radio"]:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="checkbox"]:focus {
  outline: none;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup.sv_q_radiogroup_inline:not(:last-child),
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_checkbox.sv_q_checkbox_inline:not(:last-child) {
  margin-right: 1.5em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup>label,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_checkbox>label {
  white-space: nowrap;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_checkbox_inline,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup_inline {
  line-height: 2em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_footer {
  padding: 1em 0;
}

.sv_main .sv_container .sv_body .sv_p_root table {
  width: 100%;
  border-collapse: collapse;
}

.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix .sv_matrix_dynamic_button,
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix_dropdown .sv_matrix_dynamic_button,
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix_dynamic .sv_matrix_dynamic_button {
  padding: 0.3em 2em;
}

.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix .sv_q_checkbox_label,
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix .sv_q_radiogroup_label,
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix_dropdown .sv_q_checkbox_label,
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix_dropdown .sv_q_radiogroup_label,
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix_dynamic .sv_q_checkbox_label,
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix_dynamic .sv_q_radiogroup_label {
  margin-right: 1.5em;
}

.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix td {
  min-width: 10em;
}

.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix td .sv_q_m_label {
  position: static;
}

.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix_dropdown .sv_qcbc .sv_q_checkbox_control_label {
  margin-right: 1.5em;
}

.sv_main .sv_container .sv_body .sv_p_root table td,
.sv_main .sv_container .sv_body .sv_p_root table th {
  text-align: left;
  padding: 0 1em;
}

.sv_main .sv_container .sv_body .sv_p_root table td {
  padding: 0.5em;
  padding-right: 2em;
}

.sv_main .sv_container .sv_body .sv_p_root table th {
  line-height: 1.3em;
  padding: 0.5em;
  vertical-align: bottom;
  font-weight: normal;
}

.sv_main .sv_container .sv_body .sv_p_root fieldset.sv_qcbc {
  line-height: 2em;
  padding-top: 0.1em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_other {
  margin-left: 1em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
  line-height: 2.3em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item {
  cursor: pointer;
  word-spacing: -0.3em;
  font-weight: normal;
  display: inline;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item>* {
  word-spacing: initial;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
  display: inline-block;
  min-width: 4em;
  padding: 0 0.3em;
  text-align: center;
  margin-left: 1.2em;
  border-radius: 4px;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item:not(:first-child) .sv_q_rating_item_text:not(:hover) {
  /* border-left-color: transparent; */
}

/* .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_min_text {
            margin-right: 0.5em;
     }
          .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_max_text {
            margin-left: 0.5em;
             } */

.sv_main .sv_container .sv_body .sv_nav {
  padding: 1em 0;
  min-height: 2em;
}

.sv_main .sv_container .sv_body .sv_nav .sv_start_btn,
.sv_main .sv_container .sv_body .sv_nav .sv_next_btn,
.sv_main .sv_container .sv_body .sv_nav .sv_complete_btn {
  float: right;
}

.sv_main .sv_container .sv_body .sv_nav .sv_prev_btn {
  float: left;
}



/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(190, 190, 190);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}


/* .svd-designer-container--left-side {
  width: 16% !important;
  max-width: 16% !important;
  flex-basis: 16% !important;
} */

@media only screen and (max-width : 1405px) {
  #design .sv_q_rating {
    font-size: 15px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 6.1em;
    transition: min-width 1s linear;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2.3em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 1305px) {
  #design .sv_q_rating {
    font-size: 15px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 5.1em;
    transition: min-width 1s linear;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2.4em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 1135px) {
  #design .sv_q_rating {
    font-size: 14.5px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 4.3em;
    transition: min-width 1s linear;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2.4em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 945px) {
  #design .sv_q_rating {
    font-size: 14px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 4em;
    transition: min-width 1s linear;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2.2em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 860px) {
  #design .sv_q_rating {
    font-size: 14px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 3.5em;
    transition: min-width 1s linear;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2.1em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 575px) {
  #design .sv_q_rating {
    font-size: 13px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 3.2em;
    transition: min-width 1s linear;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 498px) {
  #design .sv_q_rating {
    font-size: 12.8px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 2.9em;
    transition: min-width 1s linear;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 448px) {
  #design .sv_q_rating {
    font-size: 12.5px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 2.5em;
    transition: min-width 1s linear;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 391px) {
  #design .sv_q_rating {
    font-size: 12.4px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 2.2em;
    transition: min-width 1s linear;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 326px) {
  #design .sv_q_rating {
    font-size: 12.4px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 2em;
    transition: min-width 1s linear;
  }

  #design .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 1.9em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 1430px) {
  .sv_q_rating {
    font-size: 15px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 4em;
    margin-left: 10px;
    transition: min-width 1s linear;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2.2em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 1130px) {
  .sv_q_rating {
    font-size: 15px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 3.5em;
    margin-left: 10px;
    transition: min-width 1s linear;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2.2em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 1000px) {
  .sv_q_rating {
    font-size: 14.9px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 3.4em;
    margin-left: 8px;
    transition: min-width 1s linear;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2.1em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 920px) {
  .sv_q_rating {
    font-size: 14.8px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 3em;
    margin-left: 9px;
    transition: min-width 1s linear;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2.1em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 850px) {
  .sv_q_rating {
    font-size: 14.7px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 2.7em;
    margin-left: 7px;
    transition: min-width 1s linear;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2.1em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 768px) {
  .sv_q_rating {
    font-size: 14.6px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 3.1em;
    margin-left: 8px;
    transition: min-width 1s linear;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 1.95em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 650px) {
  .sv_q_rating {
    font-size: 14.5px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 3.1em;
    margin-left: 8px;
    transition: min-width 1s linear;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 1.94em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 575px) {
  .sv_q_rating {
    font-size: 14.6px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 2.6em;
    margin-left: 6px;
    transition: min-width 1s linear;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 1.93em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 527px) {
  .sv_q_rating {
    font-size: 14.7px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 2.3em;
    margin-left: 7px;
    transition: min-width 1s linear;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 1.95em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 466px) {
  .sv_q_rating {
    font-size: 14.8px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 2em;
    margin-left: 7px;
    transition: min-width 1s linear;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 1.97em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 420px) {
  .sv_q_rating {
    font-size: 14.8px;
    transition: font-size 1s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 1.75em;
    margin-left: 5px;
    transition: min-width 1s linear;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 1.8em;
    transition: line-height 1s linear;
  }
}

@media only screen and (max-width : 366px) {
  .sv_q_rating {
    font-size: 14.3px;
    transition: font-size 0.5s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 1.72em;
    margin-left: 4px;
    transition: min-width 0.5s linear;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 1.9em;
    transition: line-height 0.5s linear;
  }
}

@media only screen and (max-width : 350px) {
  .sv_q_rating {
    font-size: 13.5px;
    transition: font-size 0.5s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 1.49em;
    margin-left: 6px;
    transition: min-width 0.5s linear;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 1.em;
    transition: line-height 0.5s linear;
  }
}

@media only screen and (max-width : 300px) {
  .sv_q_rating {
    font-size: 12.7px;
    transition: font-size 0.5s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 1.5em;
    margin-left: 4px;
    transition: min-width 0.5s linear;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 1.8em;
    transition: line-height 0.5s linear;
  }
}

@media only screen and (max-width : 280px) {
  .sv_q_rating {
    font-size: 12.3px;
    transition: font-size 0.5s linear;
    overflow: hidden;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
    min-width: 1em;
    transition: min-width 0.5s linear;
    margin-left: 4px;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 1.6em;
    transition: line-height 0.5s linear;
  }
}

.svd_container .svd_content{
  padding: 10px !important;
}

.sv_body{
  border-top: 1px solid #cdcdcd00 !important;
}
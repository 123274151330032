.transaction-view {
    padding-left: 0 !important;
}

.comment-icon {
    font-size: 24px;
}

.commentClass {
    color: var(--custom-primary-color) !important;
    border: 1px solid var(--gray) !important;
}

.image-label {
    text-align: start;
}

.checkCircle {
    display: inline-block;
    position: relative;
    left: -6px;
}

.image-info {
    text-align: start;
    padding-left: 20px;
    border-left: 2px dashed var(--gray);
}

.transaction-view {

    .react-bootstrap-table {
        overflow: visible;
    }

    .col-md-8 {
        max-width: 100% !important;
    }

    .my-issue-reponses {
        height: calc(100vh - 350px);
        overflow-y: scroll;
        overflow-x: hidden;
    }

    table {

        th,
        td {
            padding: 8px !important;
            padding-left: 0;
        }
    }
}

.commentDate {
    padding-left: 10px;
}

.image-comment {
    display: inline-block;
    padding-bottom: 5px !important;
}

.status-filter-button {
    height: 80px;
    width: 87px;
    background: #f7fafc;
    border-radius: unset;
    border: 1px solid black;

    &.status-fa5c7c {
        color: #fa5c7c;
        border-color: #fa5c7c;
    }

    &.status-e91e63 {
        color: #e91e63;
        border-color: #e91e63;
    }

    &.status-39afd1 {
        color: #39afd1;
        border-color: #39afd1;
    }

    &.status-ff7600 {
        color: #ff7600;
        border-color: #ff7600;
    }

    &.status-0acf97 {
        color: #0acf97;
        border-color: #0acf97;
    }


    &.status-666666 {
        color: #666666;
        border-color: #666666;
    }
}

.active-status-button {
    color: white !important;


    &.status-fa5c7c {
        background-color: #fa5c7c;
    }

    &.status-e91e63 {
        background-color: #e91e63;
    }

    &.status-39afd1 {
        background-color: #39afd1;
    }

    &.status-ff7600 {
        background-color: #ff7600;
    }

    &.status-0acf97 {
        background-color: #0acf97;
    }


    &.status-666666 {
        background-color: #666666;
    }
}